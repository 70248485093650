import React, { useState, useEffect } from "react";
import LOGIN from "../assets/images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { useSetPasswordMutation } from "../services/apis";
import { PopUp } from "../utils/alert";
import Loader from "../utils/loader";
import { errors } from "../utils/error";
import EPRiskLogo from '../assets/images/EPRisk-logo.png'
const SetPassword = () => {
  const tokenId = window.location.href?.split("=")[1];
  const isToken = window.location.href.search("token");

  const navigate = useNavigate();

  const [setPassword, { data, isLoading, error }] = useSetPasswordMutation();

  const [pass, setPass] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isToggle, setIsToggle] = useState(false);
  const [isToggleTwo, setIsToggleTwo] = useState(false);


  const handleResetPassword = (e) => {
    e.preventDefault();
    if (!tokenId) {
      PopUp(
        "You are not authorized",
        "Please go to forgot password first",
        "error"
      );
    }
    if (!pass) {
      PopUp("Please enter a password", "", "error");
      return;
    }
    if (!confirmPassword) {
      PopUp("Please enter a password", "", "error");
      return;
    }

    const dataObject = {
      password: pass,
      confirm_password: confirmPassword,
      token: tokenId,
    };

    const dataObject2 = {
      password: pass,
      confirm_password: confirmPassword,
      id: tokenId,
    };

    setPassword(isToken === -1 ? dataObject2 : dataObject);
  };

  useEffect(() => {
    if (data?.success) {
      PopUp("Password reset successfully", "", "success");
      navigate("/");
    }
    errors(error);
  }, [data, error]);

  return (
    <div className="container-scroller login-bg">
      <div className="container page-body-wrapper full-page-wrapper">
        <div className="row flex-grow">
          <div className="loginLeft col-md-6 d-flex align-items-center pl-md-5">
            <img src={EPRiskLogo}  className="ml-md-5" />
          </div>
          <div className="col-lg-6 d-flex align-items-start flex-column justify-content-center">
            <div>
              <div className="loginRight">
                <div className="brand-logo text-center mt-3 mb-4 mb-md-5">
                  <img src={LOGIN} />
                </div>
                <h3 className="text-center mb-4 weight-600">Set Password</h3>
                <div className="auth-form-light text-left pb-md-0">
                  <form action="dashboard.php">
                    <div className="form-group password-field">
                      <input
                        type={isToggle ? "text" : "password"}
                        className="form-control unlock-icon weight-400"
                        value={pass}
                        onChange={(e) => setPass(e.target.value)}
                        placeholder="Create New Password*"
                      />
                      {isToggle ? (
                        <i
                          className="showPassword text-black"
                          onClick={() => setIsToggle(!isToggle)}
                        />
                      ) : (
                        <i
                          className="showPassword text-black"
                          onClick={() => setIsToggle(!isToggle)}
                        />
                      )}
                    </div>
                    <div className="form-group password-field">
                      <input
                        type={isToggleTwo ? "text" : "password"}
                        className="form-control unlock-icon weight-400"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        placeholder="Confirm Password*"
                      />
                       {isToggleTwo ? (
                        <i
                          className="showPassword text-black"
                          onClick={() => setIsToggleTwo(!isToggleTwo)}
                        />
                      ) : (
                        <i
                          className="showPassword text-black"
                          onClick={() => setIsToggleTwo(!isToggleTwo)}
                        />
                      )}
                    </div>
                    <Link to="/">
                      {" "}
                      <button
                        type="submit"
                        className="btn btn-block btn-primary rounded-pill btn-lg"
                        onClick={handleResetPassword}
                      >
                        {isLoading ? <Loader /> : "Set"}
                      </button>
                    </Link>
                  </form>
                </div>
                <div className="mt-5" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetPassword;
